import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from '../app.routes';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, NavbarComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [HeaderComponent, FooterComponent, NavbarComponent]
})
export class NavbarModule { }
