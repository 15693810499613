import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
   cookieValue = 'UNKNOWN';
  feedbackForm:FormGroup = new FormGroup({
    email:new FormControl(),
    mobile:new FormControl(),
    message:new FormControl(),
  });
  constructor(private http:HttpClient, private cookieService: CookieService ) { }
  submitFeedback() {
    let {value:feedbackForm} = this.feedbackForm;

    this.http.post(`mail_helper.php`,feedbackForm).subscribe(data=>{

  },err=>{
    // console.log("error: ",err);
    })
    this.feedbackForm.reset();
  }
  ngOnInit() {
  }

  jsonToQueryString(json) {
    return '?' +
      Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
      }).join('&');
  }
}
