import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {
  feedbackForm:FormGroup = new FormGroup({
    email:new FormControl(),
    mobile:new FormControl(),
    message:new FormControl(),
  })

  constructor(private http:HttpClient) { }
  submitFeedback() {
    let {value:feedbackForm} = this.feedbackForm;
    this.http.post(`mail_helper.php`,feedbackForm).subscribe(data=>{
      this.feedbackForm.reset();
    
  },err=>{
    
    // console.log("errrrrrrrrrrrrr",err);
    })
    
    
  }

  ngOnInit() {
  }

}
