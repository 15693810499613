import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HowToJoinComponent } from './how-to-join/how-to-join.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from '../app.routes';
import { CurrentMembersComponent } from './current-members/current-members.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
@NgModule({
  declarations: [HowToJoinComponent, CurrentMembersComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    HowToJoinComponent
  ]
})
export class MembershipModule { }
