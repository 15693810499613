import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Variable } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navbarOpen: boolean = false;
  activeToggle: boolean = false
  dropdownToggle: boolean = false;
  toggle: boolean = true;
  navbarHide = false;
  count: number = 0;

  toggleNavbar() {
    this.count++;
    if (this.count == 1) {
      // console.log(this.count, "count1")
      this.navbarHide = true;
    }
    if (this.count == 2) {
      this.navbarHide = false;
      this.count = 0;
    }
    // console.log("this is counted");
    this.navbarOpen = !this.navbarOpen;
    this.activeToggle = !this.activeToggle;
    localStorage.removeItem('active');
    this.toggle = !this.toggle;
    let abc = this.toggle;
    localStorage.setItem('active', abc.toString());
  }

  dropdown() {
    this.dropdownToggle = !this.dropdownToggle;
  }

  homePage() {
    this.router.navigate(['/home']);
  }

  aboutUs() {
    this.router.navigate(['/about-us']);
  }
  
  resources() {
    this.router.navigate(['/resources']);
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
