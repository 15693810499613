import { Component, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked {
  title = 'ucrc-frontend';
  toggle: boolean = true;

  ngAfterContentChecked() {
    let checkNavBar = localStorage.getItem('active');
    checkNavBar === 'false' ? this.toggle = true : this.toggle = false;
  }

  ngOnInit() {
    localStorage.removeItem('active');
  }
}
