import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardOfDirectorsComponent } from './board-of-directors/board-of-directors.component';
import { ManagementPageComponent } from './management-page/management-page.component';
import { FinancialStatementComponent } from './financial-statement/financial-statement.component';
import { AnnualReportComponent } from './annual-report/annual-report.component';
import { LoanFileReviewComponent } from './loan-file-review/loan-file-review.component';

import { RouterModule } from '@angular/router';
import { appRoutes } from '../app.routes';
import { MissionComponent } from './mission/mission.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BoardOfDirectorsComponent,
    ManagementPageComponent,
    FinancialStatementComponent,
    AnnualReportComponent,
    LoanFileReviewComponent,
    MissionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    BoardOfDirectorsComponent,
    ManagementPageComponent,
    FinancialStatementComponent,
    AnnualReportComponent,
    LoanFileReviewComponent
  ]
})
export class AboutUsModule { }
