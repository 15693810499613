import { Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AnnualReportComponent } from './about-us/annual-report/annual-report.component';
import { BoardOfDirectorsComponent } from './about-us/board-of-directors/board-of-directors.component';
import { ManagementPageComponent } from './about-us/management-page/management-page.component';
import { FinancialStatementComponent } from './about-us/financial-statement/financial-statement.component';
import { LoanFileReviewComponent } from './about-us/loan-file-review/loan-file-review.component';
import { MissionComponent } from './about-us/mission/mission.component';
import { LendingComponent } from './lending/lending.component';
import { LendingTeamComponent } from './lending/lending-team/lending-team.component';
import { ProductsComponent } from './lending/products/products.component';
import { MembershipComponent } from './membership/membership.component';
import { HowToJoinComponent } from './membership/how-to-join/how-to-join.component';
import { CurrentMembersComponent } from './membership/current-members/current-members.component';
import { MarketInformationComponent } from './resources/market-information/market-information.component';
import { RequirementsComponent } from './resources/requirements/requirements.component';
import { UsefulLinksComponent } from './resources/useful-links/useful-links.component';
import { ErrorComponent } from './error/error.component';

export const appRoutes:Routes = [
    {
        path:"",
        component:HomePageComponent
    },
    {
        path:"home",
        component:HomePageComponent
    },
    {
        path:"about-us",
        component:AboutUsComponent
    },
    {
        path:"about-us/annual-report",
        component:AnnualReportComponent
    },
    {
        path:"about-us/board-of-directors",
        component:BoardOfDirectorsComponent
    },
    {
        path:"about-us/management",
        component:ManagementPageComponent
    },
    {
        path:"about-us/financial-statements",
        component:FinancialStatementComponent
    },
    {
        path:"about-us/loan-file-review",
        component:LoanFileReviewComponent
    },
    {
        path:"about-us/mission",
        component:MissionComponent
    },
    {
        path:"lending",
        component:LendingComponent
    },
    {
        path:"lending/lending-team",
        component:LendingTeamComponent
    },
    {
        path:"lending/products",
        component:ProductsComponent
    },
    {
        path:"membership",
        component:MembershipComponent
    },
    {
        path:"membership/how-to-join",
        component:HowToJoinComponent
    },
    {
        path: "membership/current-members",
        component: CurrentMembersComponent
    },
    {
        path:"resources/market-information",
        component:MarketInformationComponent
    },
    {
        path:"resources/requirements",
        component:RequirementsComponent
    },
    {
        path:"resources/useful-links",
        component:UsefulLinksComponent
    },
    {
        path:"**",
        component:ErrorComponent
    },
]