import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-useful-links',
  templateUrl: './useful-links.component.html',
  styleUrls: ['./useful-links.component.scss']
})
export class UsefulLinksComponent implements OnInit {
  feedbackForm: FormGroup = new FormGroup({
    email: new FormControl(),
    mobile: new FormControl(),
    message: new FormControl(),
  })

  constructor(private http: HttpClient) { }
  submitFeedback() {
    let { value: feedbackForm } = this.feedbackForm;
    this.http.post(`mail_helper.php`, feedbackForm).subscribe(data => {
      this.feedbackForm.reset();

    }, err => {

      // console.log("errrrrrrrrrrrrr",err);
    })
    this.feedbackForm.reset();


  }

  ngOnInit() {
  }

}
