import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-join',
  templateUrl: './how-to-join.component.html',
  styleUrls: ['./how-to-join.component.scss']
})
export class HowToJoinComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
