import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsefulLinksComponent } from './useful-links/useful-links.component';
import { RequirementsComponent } from './requirements/requirements.component';
import { MarketInformationComponent } from './market-information/market-information.component';

import { RouterModule } from '@angular/router';
import { appRoutes } from '../app.routes';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ UsefulLinksComponent, RequirementsComponent, MarketInformationComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule
  ],
  exports: [ 
    UsefulLinksComponent, 
    RequirementsComponent, 
    MarketInformationComponent
  ]
})
export class ResourcesModule { }
