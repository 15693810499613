import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-of-directors',
  templateUrl: './board-of-directors.component.html',
  styleUrls: ['./board-of-directors.component.scss']
})
export class BoardOfDirectorsComponent implements OnInit {

  panelOpenState = false;

  toggleActive: boolean = false;
  activToggle: boolean = false;
  
  constructor() { }

  toggle() {
    this.activToggle = !this.activToggle;
    this.toggleActive = !this.toggleActive
  }

  ngOnInit() {
    console.log("It is in board of directors")
  }

}
