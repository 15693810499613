import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from  '@angular/router';
import { appRoutes } from './app.routes';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HttpClientModule } from '@angular/common/http';
import { NavbarModule } from './navbar/navbar.module';
import { AboutUsModule } from './about-us/about-us.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { LendingModule } from './lending/lending.module';
import { LendingComponent } from './lending/lending.component';
import { MembershipModule } from './membership/membership.module';
import { MembershipComponent } from './membership/membership.component';
import { ResourcesModule } from './resources/resources.module';
import { ResourcesComponent } from './resources/resources.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorComponent } from './error/error.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutUsComponent,
    LendingComponent,
    MembershipComponent,
    ResourcesComponent,
    ErrorComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    NavbarModule,
    AboutUsModule,
    LendingModule,
    MembershipModule,
    ResourcesModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
